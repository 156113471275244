$(document).ready(function() {
    // Das erste Bild in der mittleren Spalte wird eingeblendet.
    $('#col-center .cycle div:first, #col-right .thumbnails').fadeIn(1000);

    // Falls der Text in der rechten Spalte ebenfalls mit dem Bild
    // wechseln soll, hat das Elternelement die Klasse ".cycle".
    // Hier wird zunächst der erste Text eingeblendet.
    $('#col-right .cycle > div:first').fadeIn(1000);

    $('.cycle').each(function(index) {
        // Interval in Millisekunden für jeden Bildwechsel
        var timeout = 4500;

        // Falls in der rechten Seite der Text in einem weiteren Slider
        // (= Klasse .cycle existiert) ebenfalls synchron mit dem ersten
        // Slider getauscht werden soll, wird der automatische Bildwechsel
        // deaktiviert.
        if ($('#col-right .cycle').length) {
            timeout = 0;
        }

        $(this).cycle({
            fx: 'fade',
            timeout: timeout,
            pause: 1,
            containerResize: 1,
            pager: '#col-right .thumbnails',
            pagerAnchorBuilder: function(idx, slide) {
                // Nur für den ersten Slider in der mittleren Spalte wird
                // ein Pager angelegt
                if (index == 0) {
                    return '<a href="#" class="fadeIn" style="background-image: ' + slide.style.backgroundImage.replace(/"/g, '') + ';"></a>'
                } else {
                    // Mögliche Slider in der rechten Spalte nutzen den
                    // Pager des ersten Slider synchron
                    return '#col-right .thumbnails a:eq(' + idx + ')';
                }
            }
        });
    });
});
